import React, { useState, useEffect } from "react";
import "./ThirdPartyAuthLogin.styles.scss";

import auth0Img from "../../../assets/icons/auth0.svg";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import { useAuth0 } from "@auth0/auth0-react";

import { postSignInThunkAction, postPatientSignInThunkAction } from "../../../redux/auth/auth.slice";
import { getTokenFromAuth0ThunkAction } from "../../../redux/thirdPArtyAuth/thirdPartyAuth.slice";
import {
    isGettingTokenFromAuth0Selector,
    tokenFromAuth0DataSelector,
} from "../../../redux/thirdPArtyAuth/thirdPartyAuth.selectors";
import { useSelector } from "react-redux";
import { examplePayloadSelector,isPatientSignInLoadingSelector,isPatientSignInCompletedSelector, isPostSignInSelector, isPostSignIsCompletedSelector } from "../../../redux/auth/auth.selectors";

interface ThirdPartyAuthLoginProps {
    setIsSignedIn: React.Dispatch<React.SetStateAction<boolean>>;
    isPatient: boolean;
    setIsPatient: React.Dispatch<React.SetStateAction<boolean>>;
}

const ThirdPartyAuthLogin: React.FC<ThirdPartyAuthLoginProps> = ({ setIsSignedIn, isPatient, setIsPatient}) => {
     const { isAuthenticated, user,loginWithPopup, getAccessTokenSilently } = useAuth0();
    const dispatch = useDispatch<AppDispatch>(); 
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const isGettingTokenFromAuth0 = useSelector(isGettingTokenFromAuth0Selector)
    const tokenFromAuth0Data = useSelector(tokenFromAuth0DataSelector)
    const examplePayload = useSelector(examplePayloadSelector)  
    const isPatientSignInCompleted = useSelector(isPatientSignInCompletedSelector)
    const isPatientSignInLoading = useSelector(isPatientSignInLoadingSelector)
    const isPostSignInCompleted = useSelector(isPostSignIsCompletedSelector)
    const isPostSignIn = useSelector(isPostSignInSelector)
  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently();
        dispatch(getTokenFromAuth0ThunkAction({ data: { auth0Token: token, user } }));
    }
    if (user && !isGettingTokenFromAuth0) {
      getToken()
    }
  }, [isAuthenticated]);
  
    useEffect(() => {
        if (tokenFromAuth0Data && tokenFromAuth0Data.jwtToken) {
            setIsSignedIn(true);
        }
    }, [tokenFromAuth0Data]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (errorMessage) {
            timer = setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }
        return () => {
            if (timer) clearTimeout(timer);
        };
    }, [errorMessage]);

    useEffect(() => {
        if ((!isPostSignIn && isPostSignInCompleted)||(!isPatientSignInLoading && isPatientSignInCompleted)) {
            setIsSignedIn(true);
        }
    }, [examplePayload, isPostSignInCompleted, isPostSignIn, isPatientSignInCompleted]);

    const handleLoginAuth = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            if(isPatient){
                dispatch(
                    postPatientSignInThunkAction({
                        data: { userId: email, password: password, covid: false},
                    })
                );
            }else{
                dispatch(
                    postSignInThunkAction({
                        data: { key: email, password: password, timezone: "2022-12-27T18:02:14.894Z" },
                    })
                );
            }
            
            setErrorMessage("");
            setEmail("");
            setPassword("");
        } catch (error) {
            setErrorMessage("Invalid email or password");
        }
    };

    const handleToggleChange = () => {
        setIsPatient(!isPatient);
    };

    return (
        <div className="third-party-auth">
            <div className="third-party-auth__container">
                <h1 className="third-party-auth__Maintitle">Third Party Authorization Manager</h1>
                <h2 className="third-party-auth__title">Login</h2>
                <form className="third-party-auth__form" onSubmit={handleLoginAuth}>
                    <input
                        type={ isPatient ? "text" : "email"}
                        className="third-party-auth__input"
                        placeholder="Email"
                        required
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    <input
                        type="password"
                        className="third-party-auth__input"
                        placeholder="Password"
                        required
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    />
                    {errorMessage && <p className="third-party-auth__error">{errorMessage}</p>}
                    <div className="third-party-auth__toggle-container">
                        <span>Provider</span>
                        <label className="third-party-auth__toggle">
                            <input type="checkbox" checked={isPatient} onChange={handleToggleChange} />
                            <span className="third-party-auth__toggle-slider"></span>
                        </label>
                        <span>Patient</span>
                    </div>
                    <button type="submit" className="third-party-auth__button third-party-auth__button--primary">
                        Login as a {isPatient ? "Patient" : "Provider"}
                    </button>
                </form>
                <div className="third-party-auth__separator">
                    <span>or</span>
                </div>
                <button
                    className="third-party-auth__button third-party-auth__button--auth0"
                    onClick={() => loginWithPopup()}
                >
                    <img src={auth0Img} alt="Auth0" width={20} />
                    Sign in with Auth0
                </button>
            </div>
        </div>
    );
};

export default ThirdPartyAuthLogin;
