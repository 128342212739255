import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CEThunkPayload } from "../../api/gateway/carexGateway";
import { AxiosResponse } from "axios";

import { IThirdPartyAuthState } from "./thirdPartyAuth.types";

import PatientThirdPartyAuthData from "../../api/ThirdPartyAuthData.api";

const initialState: IThirdPartyAuthState = {
    thirdPartyAuthToken: [],
    isLoadingThirdPartyAuthToken: false,
    tokenFromAuth0Data: {},
    isGettingTokenFromAuth0: false,
    isCreatingThirdPartyAuthToken: false,
    creationSuccessful: false,
    createdTokenData: {},
};

const payloadFactory = (payload: CEThunkPayload<any>, service: Promise<any>) => {
    const success = payload.onSuccess ? payload.onSuccess : (res: AxiosResponse) => Promise.resolve(res);
    const error = payload.onError ? payload.onError : (err: AxiosResponse) => Promise.reject(err);
    return service.then(success).catch(error);
};

export const getPatientThirdPartyAuthDataThunkAction = createAsyncThunk(
    "getPatientThirdPartyAuthData",
    (payload: any) => payloadFactory(payload, PatientThirdPartyAuthData.getThirdPartyAuthData())
);

export const addNewThirdPartyAuthDataThunkAction = createAsyncThunk("addNewThirdPartyAuthData", (payload: any) =>
    payloadFactory(payload, PatientThirdPartyAuthData.addNewThirdPartyAuthData(payload))
);

export const updateThirdPartyAuthDataThunkAction = createAsyncThunk("updateThirdPartyAuthData", (payload: any) =>
    payloadFactory(payload, PatientThirdPartyAuthData.updateThirdPartyAuthData(payload))
);

export const deleteThirdPartyAuthDataThunkAction = createAsyncThunk("deleteThirdPartyAuthData", (payload: any) =>
    payloadFactory(payload, PatientThirdPartyAuthData.deleteThirdPartyAuthData(payload))
);
export const getTokenFromAuth0ThunkAction = createAsyncThunk("getTokenFromAuth0", (payload: any) =>
    payloadFactory(payload, PatientThirdPartyAuthData.getTokenFromAuth0(payload))
);

const thirdPartyAuthSlice = createSlice({
    name: "thirdPartyAuth",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(addNewThirdPartyAuthDataThunkAction.pending, state => {
            state.isCreatingThirdPartyAuthToken = true;
            state.thirdPartyAuthToken = [];
            state.creationSuccessful = false;
        });
        builder.addCase(addNewThirdPartyAuthDataThunkAction.fulfilled, (state, payload) => {
            state.isCreatingThirdPartyAuthToken = false;
            state.creationSuccessful = true;
            state.createdTokenData = payload.payload;
        });
        builder.addCase(addNewThirdPartyAuthDataThunkAction.rejected, state => {
            state.isCreatingThirdPartyAuthToken = false;
            state.creationSuccessful = false;
        });
        builder.addCase(getPatientThirdPartyAuthDataThunkAction.pending, state => {
            state.isLoadingThirdPartyAuthToken = true;
        });
        builder.addCase(getPatientThirdPartyAuthDataThunkAction.fulfilled, (state, payload) => {
            state.isLoadingThirdPartyAuthToken = false;

            // eslint-disable-next-line no-console
            console.log("ESto es lo que viene payload", payload);

            if (payload.payload) {
                state.thirdPartyAuthToken = payload.payload.rows.map((token: any) => ({
                    ...token,
                    // availableScopes: token.availableScopes.map((scope: any) => ({ name: scope, consented: true }))
                }));
            }
        });

        builder.addCase(getPatientThirdPartyAuthDataThunkAction.rejected, state => {
            state.isLoadingThirdPartyAuthToken = false;
        });
        builder.addCase(updateThirdPartyAuthDataThunkAction.pending, state => {
            state.isLoadingThirdPartyAuthToken = true;
        });
        builder.addCase(updateThirdPartyAuthDataThunkAction.fulfilled, (state, payload) => {
            state.isLoadingThirdPartyAuthToken = false;
            state.thirdPartyAuthToken = state.thirdPartyAuthToken.map(token =>
                token.id === payload.payload.id ? payload.payload : token
            );
        });
        builder.addCase(updateThirdPartyAuthDataThunkAction.rejected, state => {
            state.isLoadingThirdPartyAuthToken = false;
        });
        builder.addCase(deleteThirdPartyAuthDataThunkAction.pending, state => {
            state.isLoadingThirdPartyAuthToken = true;
        });
        builder.addCase(deleteThirdPartyAuthDataThunkAction.fulfilled, (state, payload) => {
            state.isLoadingThirdPartyAuthToken = false;
            state.thirdPartyAuthToken = state.thirdPartyAuthToken.filter(token => token.id !== payload.payload.id);
        });
        builder.addCase(deleteThirdPartyAuthDataThunkAction.rejected, state => {
            state.isLoadingThirdPartyAuthToken = false;
        });
        builder.addCase(getTokenFromAuth0ThunkAction.pending, state => {
            state.isGettingTokenFromAuth0 = true;
        });
        builder.addCase(getTokenFromAuth0ThunkAction.fulfilled, (state, payload) => {
            state.isGettingTokenFromAuth0 = false;
            state.tokenFromAuth0Data = payload.payload;
            localStorage.setItem("Token", payload.payload.jwtToken);
            localStorage.setItem("RefreshToken", payload.payload.jwtRefreshToken);
        });
        builder.addCase(getTokenFromAuth0ThunkAction.rejected, state => {
            state.isGettingTokenFromAuth0 = false;
        });
    },
});

export const {} = thirdPartyAuthSlice.actions;
export default thirdPartyAuthSlice.reducer;
