import React, { useEffect, useState } from "react";
import "./ThirdPartyAuthScopes.styles.scss";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";

import { addNewThirdPartyAuthDataThunkAction } from "../../../redux/thirdPArtyAuth/thirdPartyAuth.slice";
import { useSelector } from "react-redux";
import { createdTokenDataSelector , creationSuccessfulSelector} from "../../../redux/thirdPArtyAuth/thirdPartyAuth.selectors";

import { SCOPES } from "../variables/constants";
import { useSearchParams } from "react-router-dom";
import { Modal } from "@careexpand/cex-core-components/esm";

interface ThirdPartyAuthScopesProps {
    isPatient: boolean;
    onScopesChange: (scopes: string[]) => void;
    tableSelectedPatients: number[];
}

const ThirdPartyAuthScopes: React.FC<ThirdPartyAuthScopesProps> = ({
    isPatient,
    onScopesChange,
    tableSelectedPatients,
}) => {
    const [searchParams] = useSearchParams();
    const redirect_uri = searchParams.get("redirect_uri");
    const client_id = searchParams.get("client_id");
    const state = searchParams.get("state");
    const scope = searchParams.get("scope");
    const createdTokenData = useSelector(createdTokenDataSelector);
    const creationSuccessful = useSelector(creationSuccessfulSelector);
    const [selectedScopes, setSelectedScopes] = useState<string[]>([]);
    const [offlineAccessModalVisible, setOfflineAccessModalVisible] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    const allScopes = scope ? scope.split(" ") : isPatient
        ? [...SCOPES.OPENID, ...SCOPES.PATIENT]
        : [...SCOPES.OPENID, ...SCOPES.USER];
    

    useEffect(() => {
        const availableScopes = scope? scope.split(" "):isPatient
        ? [...SCOPES.OPENID, ...SCOPES.PATIENT]
        : [...SCOPES.OPENID, ...SCOPES.USER];//, ...SCOPES.USER];
        setSelectedScopes(availableScopes);
    }, [isPatient]);

    useEffect(() => {
        onScopesChange(selectedScopes);
    }, [selectedScopes, onScopesChange]);

    const handleScopeChange = (scope: string) => {
        setSelectedScopes(prevScopes => {
            if (prevScopes.includes(scope)) {
                return prevScopes.filter(s => s !== scope);
            } else {
                return [...prevScopes, scope];
            }
        });
    };
    useEffect(() => {
        //eslint-disable-next-line
        console.log("selectedScopes", selectedScopes);
        if(creationSuccessful &&createdTokenData&& createdTokenData.redirect_url) {
            window.location.href = createdTokenData.redirect_url;
        }   
    }, [creationSuccessful]);
    
    const checkAuthorize = () => {
        
        if (isPatient && selectedScopes.includes("offline_access")) {
            setOfflineAccessModalVisible(true);
        } else {
            handleAuthorize()
        }
    }
    const handleAuthorize = () => {
        try {
            setOfflineAccessModalVisible(false);
            (async () => {
                //eslint-disable-next-line
                console.log("Authorizing scopes:");
                //eslint-disable-next-line
                console.log(selectedScopes);
                //eslint-disable-next-line
                console.log("tableSelectedPatients:");
                //eslint-disable-next-line
                console.log(tableSelectedPatients);
                const data = {
                    scopes: selectedScopes,
                    patients: tableSelectedPatients,
                    redirect_uri,
                    client_id,
                    state,
                    response_type: "code", 
                    }
                dispatch(
                    addNewThirdPartyAuthDataThunkAction({ data })).then((response) => {
                        //eslint-disable-next-line
                        console.log("response", response);
                        if(response.payload.redirect_url) {
                            window.location.href = response.payload.redirect_url;
                        }   
                    })
                    
                
            })();
        } catch (error) {
            //eslint-disable-next-line
            console.error(error);
        }
    };

    return (
        
        <div className="third-party-auth-scopes">
            
            {offlineAccessModalVisible && <Modal closeModal={() => { setOfflineAccessModalVisible(false) }}
                children={<></> }
                title="Offline Access Requested"
                titleDescription="This application is requesting offline access. This means that it will be able to access your data even when you are not using the app."
                backButton={false}
                leftButtons={false}
                buttons={[{
                    onClick: () => { setOfflineAccessModalVisible(false) },
                    displayText: "Cancel",
                    theme: { primaryColor: "#FFF", textColor: "#FF4A4A" }
                },{
                    onClick: handleAuthorize,
                    displayText: "Continue",
                    theme: { primaryColor: "#FFF", textColor: "#009EFC" }
                }, ]} />
            }
            <h3 className="third-party-auth-scopes__header">Scopes</h3>
            <ul className="third-party-auth-scopes__list">
                {allScopes.map((scope, index) => (
                    <li key={index} className="third-party-auth-scopes__item">
                        <label className="third-party-auth-scopes__label">
                            <input
                                type="checkbox"
                                className="third-party-auth-scopes__checkbox"
                                checked={selectedScopes.includes(scope)}
                                onChange={() => handleScopeChange(scope)}
                            />
                            <span className="third-party-auth-scopes__scope-text">{scope}</span>
                        </label>
                    </li>
                ))}
            </ul>
            <button className="third-party-auth-scopes__button" onClick={()=>{ checkAuthorize()} }>
                Authorize
            </button>
        </div>
    );
};

export default ThirdPartyAuthScopes;
