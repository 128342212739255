import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import PatientSurvey from "./pages/PatientSurveys/PatientSurvey";
import AuthFrame from "./frames/AuthFrame";
import Base from "./pages/Base";
import ConnectYourAccounts from "./pages/ConnectYourAccounts/ConnectYourAccounts";
import ThirdPartyAuth from "./pages/ThirdPartyAuthLogin/ThirdPartyAuth";
import OTPValidation from "./pages/OTPValidation/OTPValidation";
import Auth0ProviderWithHistory from "./lib/Auth0Provider/Auth0ProviderWithHistory";

function App() {
    return (
        <div className="cexAuthApp">
            <BrowserRouter>
                <Routes>
                    <Route element={<PatientSurvey />} path="/survey" />
                    <Route element={<Base />} path="/" />
                    <Route element={<ConnectYourAccounts />} path="/connectyouraccounts" />
                    <Route element={<Auth0ProviderWithHistory><ThirdPartyAuth /></Auth0ProviderWithHistory>} path="/thirdpartyauth" />
                    <Route element={<OTPValidation />} path="/validation" />
                </Routes>
            </BrowserRouter>
            <HashRouter>
                <Routes>
                    <Route element={<AuthFrame login />} path="/login" />
                    <Route element={<AuthFrame register />} path="/register" />
                    <Route element={<AuthFrame forgotPassword />} path="/forgot" />
                    <Route element={<AuthFrame resetPassword />} path="/reset" />
                    <Route element={<AuthFrame twofactorauth />} path="/2fa" />
                </Routes>
            </HashRouter>
        </div>
    );
}

export default App;



//http://localhost:3000/thirdPartyAuth?client_id=tk_25f7ec1e21e995422&redirect_uri=https%3A%2F%2Finferno.healthit.gov%2Fsuites%2Fcustom%2Fsmart%2Fredirect&state=940cbfa03c37f32d11c886f5f94630a2
//https://app-dev.careexpand.com/thirdPartyAuth?client_id=tk_25f7ec1e21e995422&redirect_uri=https%3A%2F%2Finferno.healthit.gov%2Fsuites%2Fcustom%2Fsmart%2Fredirect&state=32b11ac8eb406ece02fdcc4c71a4202e